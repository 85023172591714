// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-wedding-post-js": () => import("/opt/build/repo/site/src/templates/wedding-post.js" /* webpackChunkName: "component---src-templates-wedding-post-js" */),
  "component---src-pages-about-jsx": () => import("/opt/build/repo/site/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wedding-jsx": () => import("/opt/build/repo/site/src/pages/wedding.jsx" /* webpackChunkName: "component---src-pages-wedding-jsx" */)
}

